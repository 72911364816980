import React,{useState, useEffect} from "react"
import { Helmet } from "react-helmet";
import { Container,Row,Col } from "react-bootstrap"
import { useLocation } from "@reach/router";
import wordsToNumbers from 'words-to-numbers';
import PropertyBenner from "./PropertyBanner/PropertyBenner";
import PropertyHeading from "./PropertyHeading/PropertyHeading";
import PropertyFacts from "./PropertyFacts/PropertyFacts";
import CollapseBlock from "./CollapseBlock/CollapseBlock";
import PropertyArea from "./PropertyArea/PropertyArea";
import MapComponent from "./Map/Map";
import VideoArea from "./VideoArea/VideoArea";
import MemberCard from "../MemberCard/MemberCard"

import MemberImg from "../../images/person.jpg"
import "./NewProjectsDetail.scss"
import InventoryTrend from "./InventoryTrend/InventoryTrend";
import AvailableUnit from "./AvailableUnit/AvailableUnit";
import SimilarProperty from "./SimilarProperty/SimilarProperty";
import { useStaticQuery, graphql } from "gatsby"
import { removeDataAttributes } from '../../comQueryStructure'

import Modal from "react-bootstrap/Modal"
import RequestDetails from "../../components/forms/request-details-form"

// styles

// markup
const NewProjectDetails = ( props ) => {

  const [isOpen,setIsOpen] = useState(false);
  const location = useLocation();
  const [setlocation, setNewLocation] = useState(null);
  const data = useStaticQuery(graphql`
    query RightsidebarQuery {
      glstrapi {
        globalModule {
          data {
            attributes {
              PropDetails_Right_Side_Block {
                id
                Title
                Choose_Office {
                  data {
                    id
                    attributes {
                      Name
                      Email
                      URL
                      Phone
                      Direct_Phone
                      Office_Phone
                      Whatsapp_No
                      Image {
                        data {
                          attributes {
                            url
                            alternativeText
                          }
                        }
                      }
                    }
                  }
                }
                Choose_Dubai_Office {
                  data {
                    id
                    attributes {
                      Name
                      Email
                      URL
                      Phone
                      Direct_Phone
                      Office_Phone
                      Whatsapp_No
                      Image {
                        data {
                          attributes {
                            url
                            alternativeText
                          }
                        }
                      }
                    }
                  }
                }
                Content
                CTA_2_URL
                CTA_2_Label
                CTA_1_URL
                CTA_1_Label
              }
              New_Projects_Inventry_Trend
            }
          }
        }
      }
    }
  `)
  useEffect(() => {
    setNewLocation(location);
  }, []);
  const gobalData = removeDataAttributes(data)

  //console.log("property_data ==>", gobalData.glstrapi.globalModule)

  var right_side_block = gobalData.glstrapi.globalModule.PropDetails_Right_Side_Block;
  var InventryTrend = gobalData.glstrapi.globalModule.New_Projects_Inventry_Trend;


  var pre_qual_qst = '/pre-qualified/secondary-market';

  if(props.property_data && props.property_data.Project_Type === "Off-Plan"){
    pre_qual_qst = "/pre-qualified/offplan-projects"
  } else if(props.property_data && props.property_data.Property_Status === "Sale"){
    pre_qual_qst = "/pre-qualified/secondary-market"
  } else if(props.property_data && props.property_data.Property_Status === "Rent"){
    pre_qual_qst = "/pre-qualified/rental-listing"
  }

  var to_email_id = props.location.pathname.indexOf('dubai')>= 0 ? process.env.GATSBY_MAIL_TO_DUBAI : process.env.GATSBY_MAIL_TO_QATAR ;
  var newlocation = useLocation()
  
   var schemaPriceVal = ""
   var offerName = ""
   var pricerange = [];
   //console.log("pricerange",pricerange) 

   if(props?.property_data?.Price_Range){
      pricerange = props?.property_data?.Price_Range?.split(" ")
   }
    var lowPriceVal = pricerange[1]?.replaceAll(",", "")
    var highPriceVal = pricerange[4]?.replaceAll(",", "")

    if(lowPriceVal  && highPriceVal ){
      schemaPriceVal = {
        lowPrice: lowPriceVal,
        highPrice: highPriceVal}
        offerName = "AggregateOffer"
    } else{
      schemaPriceVal = {price : lowPriceVal}
      offerName = "Offer"
    }
  

   const pricevalue = JSON.stringify(schemaPriceVal , null , 1).replace(/[{}]/g,"");
  
   var testimonial_count = props.testimonials ? props.testimonials.length : 0;
   var rating_count = 0;
   props.testimonials && props.testimonials.length > 0 && props.testimonials.map((item, ind)=>{
       rating_count += parseInt(wordsToNumbers(item.starRating));
   })
   var rating_avg = rating_count/testimonial_count;
   var avgRatingVal = parseFloat(rating_avg).toFixed(1)
 

  return (
    <>
    { props.property_data.Region === "Qatar" &&
      <Helmet>
      <script type="application/ld+json">{`{

               "@context" : "http://schema.org",
               "@type" : "Product",
               "name" : "${props?.property_data?.Name}",
               "image" : "${props?.property_data?.Title_Image?.url}",
               "description" : "${props?.property_data?.Meta_Description ? props?.property_data?.Meta_Description : props.description}",
               "brand" : {
                 "@type" : "Organization",
                 "name" : "The Pearl Gates",
                 "logo" : "https://ggfx-pearlgates2.s3.eu-west-2.amazonaws.com/i.prod/pb_logo_2_043eb3ad46.png"
               },
               "aggregateRating": {
                     "@type": "AggregateRating",
                     "ratingValue": "${avgRatingVal}",
                     "reviewCount": "${testimonial_count}"
                 },
               "offers": {
               "@type": "${offerName}",
               "url": "${newlocation?.href}",
                ${pricevalue},
               "priceCurrency": "QAR",
               "availability": "https://schema.org/InStock"
                     }
         }`}</script>
     </Helmet>
    }
    
    {props?.property_data && <>
    
      <PropertyBenner banner_data={props.property_data}/>  
      <div className="property-wrapper">
      <Container>
          <Row>
            <Col xl={8}>
                <a href={'javascript:;'} onClick={(e) => window.history.back(-1)}  className="back-link">
                  <i className="right-arrow"></i>Back to New Projects
                </a>
                <PropertyHeading data={props.property_data} breadcrumbs={false}/>
            </Col>
            </Row>
        </Container>
      </div>

      
      <div className="block-wrapper">
      <Container>

          <Row>
            <Col xl={8}>
                
                  <PropertyFacts data={props?.property_data}/>
                  <CollapseBlock data={props?.property_data}/>
                  {
                    props.property_data && props.property_data.Floorplans && props.property_data.Floorplans.length > 0 && (
                      <PropertyArea Floorplans={props.property_data.Floorplans}/>
                    )
                  }
                  
                  {
                    props.property_data.Inventory_Trend && (
                      <InventoryTrend InventryTrend={props.property_data.Inventory_Trend}/>
                    )
                  }
                  
                  {
                    props.property_data && props.property_data?.Available_Units && props.property_data?.Available_Units?.available_properties?.length > 0 && <AvailableUnit data={props.property_data} Project_Name={props.property_data.Name}/>
                  }
                  

            </Col>
            <Col xl={4}>
            <div className="member-card-block">
            <MemberCard
              title={right_side_block.Title}
              content={right_side_block.Content}
              btnText={right_side_block.CTA_1_Label}
              btnTextSecondary={right_side_block.CTA_2_Label}
              btnLink={right_side_block.CTA_1_URL}
              btnLinkSecondary={pre_qual_qst ? pre_qual_qst : right_side_block.CTA_2_URL}
              Office={props.location.pathname.indexOf('dubai')>= 0 ?right_side_block.Choose_Dubai_Office:  right_side_block.Choose_Office}     
              openModalForm={() => setIsOpen(true)}
              WhatsappText={`Hi ${
                props.location.pathname.indexOf('dubai') >= 0
                  ? right_side_block.Choose_Dubai_Office.Name
                  : right_side_block.Choose_Office.Name
              }, I'm interested in property ${setlocation?.href}`}
              location={props.location}
            />
            </div>
            
            </Col>
          </Row>

      </Container>
      </div>

      {
        props.property_data.Latitude && props.property_data.Longitude && (
          <MapComponent Latitude={props.property_data.Latitude} Longitude={props.property_data.Longitude}/>
        )
      }
      
      <VideoArea property_details_area_guide={props.property_data?.Address}/>


      <Modal
            id="valuation_modal"
            show={isOpen}
            onHide={() => setIsOpen(false)}
            backdrop="static"
            keyboard={false}
            className="member-contact-modal"
          >
            {/* <Button variant="" onClick={this.closeModal} className="p-0 closepopup"><i className="icon icon-close-popup"></i></Button> */}
            <Modal.Header closeButton className="contact-close-btn memberPopup">
              <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">{props.property_data.Name} - Request Details</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <RequestDetails to_email_id={to_email_id} formName={`New Project - Request Details`} formLabel={props.property_data.Name} property_data={props.property_data}  formType={'request-details'} project_url={props.location.href} location={props.location.pathname} />
            </Modal.Body>
      </Modal>
  
    </>}

  </>
  
  )
}

export default NewProjectDetails