import { Link } from "@StarberryUtils";
import React, { useState, useEffect, useContext } from "react"
import { Row, Col, Container, Breadcrumb, Card } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import "./CollapseBlock.scss"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"

import HTMLReactParser from 'html-react-parser';
import $ from "jquery"

const CollapseBlock = (props) => {

  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey),
    );

    useEffect(()=>{

      if(currentEventKey){
        //console.log("currentEventKey", currentEventKey);
        setTimeout(function(){
          $('html, body').animate({
            scrollTop: $("#accord_"+currentEventKey).offset().top - 170
          }, 'smooth');
        }, 300)
        
      }
      
    },[currentEventKey])

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <button
        type="button"
        className={isCurrentEventKey ? 'active_card inactive_card' : 'inactive_card'}
        onClick={decoratedOnClick}
      >
        {children}
        <i
          className={isCurrentEventKey ? 'icon-minus-accordion ' : 'icon-plus-accordion'}
        ></i>
      </button>
    );
  }

  //console.log("collapseblock", props.data);

  return (
    <div className="collapse-block prop_details">
       <div className="animated">
        {
          props.data &&
          <Accordion>
            <Card id={`accord_0`}>
              <div className="accordion-header" >
                <ContextAwareToggle eventKey="0" className="accordion-header">
                  Details &amp; Key Features
                  </ContextAwareToggle>
              </div>
              <Accordion.Collapse eventKey="0">
                <div className="accordion-card accordion-card-first">
                  {props.data.Long_Description && HTMLReactParser(props.data.Long_Description)}
                  {props.data.Key_Features && HTMLReactParser(props.data.Key_Features)}
                  
                </div>
              </Accordion.Collapse>
            </Card>
            <Card id={`accord_1`}>
              <div className="accordion-header">
                <ContextAwareToggle eventKey="1">
                  Development &amp; Strategy

                  </ContextAwareToggle>
              </div>
              <Accordion.Collapse eventKey="1">

                <div className="accordion-card accordion-card-second">
                  {props.data.Development_Strategy && HTMLReactParser(props.data.Development_Strategy)}
                </div>

              </Accordion.Collapse>
            </Card>
            <Card id={`accord_2`}>
              <div className="accordion-header">
                <ContextAwareToggle eventKey="2">
                  Our Payment Plan

                  </ContextAwareToggle>
              </div>
              <Accordion.Collapse eventKey="2">

                <div className="accordion-card accordion-card-third payment_plan">
                  {/* {props.data.Our_Payment_Plan && HTMLReactParser(props.data.Our_Payment_Plan)} */}

                  {
                    props?.data?.Payment_Plan_Module && props?.data?.Payment_Plan_Module?.length > 0 && props?.data?.Payment_Plan_Module.map((item, index) => {
                        if(item?.Payment_Plan?.Payment_Installments){
                          return(                        
                            <div className="property-toolkit-item">
                              <div className="property-toolkit-info">
                                <h2>{item?.Payment_Plan?.Schedule_of_payments}</h2>
                                <h3>{item?.Payment_Plan?.Payment_Installments}</h3>
                                {
                                  item?.Payment_Plan?.Signature_Date && 
                                  <p>
                                    {item?.Payment_Plan?.Signature_Date}
                                  </p>
                                }
                                
                              </div>
                            </div>
                          )
                        } 
                    })
                  }

                </div>

              </Accordion.Collapse>
            </Card>
          </Accordion>
        }
       </div>

    </div>
  )
}

export default CollapseBlock