import * as React from "react"
import { Link } from "@StarberryUtils";
import Slider from "react-slick"
import {Container, Row,Col} from 'react-bootstrap'
import ContactCard from "../../ContactCard1/ContactCard"
import PortfolioImgMobile from "../../../images/static/portfolio_img_mobile.jpg"
import PortfolioImgTablet from "../../../images/static/portfolio_img_tablet.jpg"

import PortfolioImgMobile1 from "../../../images/static/portfolio_img_mobile1.jpg"
import PortfolioImgTablet1 from "../../../images/static/portfolio_img_tablet1.jpg"

import PortfolioImgMobile2 from "../../../images/static/portfolio_img_mobile2.jpg"
import PortfolioImgTablet2 from "../../../images/static/portfolio_img_tablet2.jpg"
import ScrollAnimation from 'react-animate-on-scroll'
import "./SimilarProperty.scss"
// styles

// markup
const SimilarProperty = () => {
 
  const settings = {
    dots: false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    responsive: [
      {
        breakpoint:1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 99999,
        // settings: "unslick",
      },
    ],
  }

  return (

  <React.Fragment>
 <div className="similar-property"> 
   <Container>
     <Row>
       <Col lg={12}>
         <h2>Similar properties that may interest you</h2>
         <div className="similar-property-list">
         <Slider {...settings}>
          <div className="animated"> 
                    <ContactCard
                      cardImgDesktop={PortfolioImgMobile}
                      cardImgTablet={PortfolioImgTablet}
                      cardImgMobile={PortfolioImgMobile}
                      cardTitle={"1 bedroom apartment for sale in Doha, Qatar"}
                      date={"1,265,670 AED"}
                      cardTag={"SSTC"}
                    />
                     </div>
                     <div className="animated" delay={400}> 
                    <ContactCard
                      cardImgDesktop={PortfolioImgMobile1}
                      cardImgTablet={PortfolioImgTablet1}
                      cardImgMobile={PortfolioImgMobile1}
                      cardTitle={
                        "Studio apartment for sale in Porto Arabia, Qatar"
                      }
                      date={"865,500 AED"}
                     
                    />
                     </div>
                     <div className="animated" delay={500}> 
                    <ContactCard
                      cardImgDesktop={PortfolioImgMobile2}
                      cardImgTablet={PortfolioImgTablet2}
                      cardImgMobile={PortfolioImgMobile2}
                      cardTitle={"Studio apartment for sale in Viva Bahriyah"}
                      date={"1,,100,203 AED"}
                      cardTag={""}
                    />
                     </div>
                  </Slider>
         </div>
       </Col>
       
     </Row>
  
    </Container>
 </div>
  </React.Fragment>
  )
}

export default SimilarProperty